import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-user/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Configure KOPE for your organisation.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Accessing Settings</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Appearance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Webhooks</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Workflows</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Custom Workspace Types</AnchorLink>
    </AnchorLinks>
    <h2>{`Accessing Settings`}</h2>
    <p>{`You can access the Organisation settings from the cogwheel (⚙️) button on the top right corner.`}</p>
    <p>{`Some settings can only be edited by a user with Admin permissions.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/147_accessing-settings.png",
      "alt": "147_accessing-settings.png"
    }}></img>
    <InlineNotification mdxType="InlineNotification">
Changes you make in `Organisation settings` affect all users of KOPE in your organisation.
    </InlineNotification>
    <h2>{`Appearance`}</h2>
    <p>{`Appearance allows you to customise the KOPE Platform for your organisation. Upload your logo image to see the platform change for your organisation’s KOPE users.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/148_organisation-appearance.png",
      "alt": "148_organisation-appearance.png"
    }}></img>
    <h2>{`Webhooks`}</h2>
    <p>{`Webhooks allow you to create subscriptions to events happening in KOPE, such as when a workflow was run. This settings page lets you see your active webhooks or create a new one.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/149_organisation-settings-webhooks.png",
      "alt": "149_organisation-settings-webhooks.png"
    }}></img>
    <h2>{`Workflows`}</h2>
    <p>{`With KOPE, you can ensure your intellectual property is hosted on your own service by integrating KOPE with your own organisation’s NuGet package service providers such as Azure DevOps Artifacts. KOPE will use this feed to retrieve and access your organisation’s workflow packages.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/150_organisation-settings-workflows.png",
      "alt": "150_organisation-settings-workflows.png"
    }}></img>
    <h2>{`Custom Workspace Types`}</h2>
    <p>{`Waiting on Confirmation of Workspaces.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/151_organisation-settings-custom-workspace.png",
      "alt": "151_organisation-settings-custom-workspace.png"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      